import { FC, useState, useEffect } from "react";
import Link from "next/link";
import { bidModalShow, buyModalShow, cancelModalShow, selModalShow, makeOfferModalShow, updateBidItem, updateBuyItem, updateCancelItem, updateSelItem, updateMakeOffer } from "../../../redux/modalSlice";
import { useDispatch } from "react-redux";
import arbDateTimeDiff from "../../../utils/display/arbDateTimeDiff";
import { useRouter } from 'next/router'
import ArbitrumSVG from "../../../public/images/svg/ArbitrumLogoSVG";

interface AssetThumbProps {
  id: string;
  imageURL: string;
  imageRaw?: string;
  name: string;
  description: string;
  unitPrice?: string;
  minBidPrice?: string;
  type?: string;
  balance?: number;
  totalSupply?: number;
  listingId?: string;
  listingType?: string;
  contractAddress: string;
  contractName?: string;
  contractSymbol?: string;
  currencyContractAddress?: string;
  currencyName?: string;
  currencySymbol?: string;
  currencyDecimals?: number;
  endTime?: number;
  sellButton?: boolean;
  buyButton?: boolean;
  cancelButton?: boolean;
  makeOfferButton?: boolean;
  bidButton?: boolean;
  isOwner?: boolean;
  topOffer?: any;
  creatorRoyalty?: number;
  floorPrice?: string;
}

const AssetThumb: FC<AssetThumbProps> = ({
  id,
  contractAddress,
  imageURL,
  imageRaw,
  name,
  description,
  unitPrice,
  minBidPrice,
  balance,
  totalSupply,
  listingId,
  listingType,
  currencyContractAddress,
  contractName,
  contractSymbol,
  currencyName,
  currencySymbol,
  currencyDecimals,
  endTime,
  type,
  sellButton,
  buyButton,
  cancelButton,
  makeOfferButton,
  bidButton,
  isOwner,
  topOffer,
  creatorRoyalty,
  floorPrice
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();
  const dispatch = useDispatch();
  const query = (listingType == "auction")
    ? { auction: listingId }
    : (listingType == "direct")
      ? { sale: listingId }
      : {};

useEffect(() => {
  const timer = setTimeout(() => {
    setIsLoading(false);
  }, 3000);  // 3 seconds of simulated loading time

  return () => {
    clearTimeout(timer);
  };
}, []);

if (isLoading) {
  return (
    <div className="flex flex-col rounded-2xl w-full bg-light-base dark:bg-jacarta-700 border dark:border-jacarta-600 border-jacarta-100 transition-all duration-300 ease-in-out mb-5">
    {/* Skeleton for Image */}
    <div className="relative w-full pb-[127%]" >
      <div className="absolute top-0 left-0 w-full h-[75%] bg-jacarta-100 dark:bg-jacarta-600 animate-pulse dark:animate-pulse rounded-t-xl"></div>
    </div>
    <div className="p-3">
      <div className="relative bottom-11">
        <div className="h-5 w-4/5 bg-jacarta-100 dark:bg-jacarta-600 animate-pulse dark:animate-pulse"></div>
        <div className="h-5 w-2/4 bg-jacarta-100 dark:bg-jacarta-600 mt-2 animate-pulse dark:animate-pulse"></div>
      </div>
      <div className="h-5 w-2/3 bg-jacarta-100 dark:bg-jacarta-600 mt-2 animate-pulse dark:animate-pulse"></div>
    </div>
  </div>
  );
}

  const navigateToItem = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (e.button === 2) return; // Prevents navigation on right-click
  };

  

  return (
    <Link
      href={{
        pathname: `/item/${contractAddress}/${id}`,
        query: query,
      }}
      passHref
    >
      <div onClick={navigateToItem}>
        <article key={`asset-${contractAddress}-${id}`}>
          <div className="assetthumbcard group h-0 pb-[170%] dark:border-jacarta-600 border-jacarta-100 relative w-full rounded-2xl overflow-hidden dark:bg-jacarta-700 bg-light-base dark:hover:bg-jacarta-600 hover:bg-jacarta-100 rounded-2.5xl block border ">
            <figure className="relative aspect-square overflow-hidden">
              {/* -- When there are no images available yet load the collection profile picture in theese 2 image tags down here.--*/}
              {/*
              <div className="">
                <img
                  src={imageURL}
                  alt=""
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  className="rounded-t-md group-hover:scale-110 duration-300 ease-out object-cover w-full h-full blur-md"
                />
                <img
                  src={imageURL}
                  alt=""
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  className="rounded-full absolute inset-0 m-auto w-[45%] h-[45%] z-10"
                />
                <div className="z-10 absolute inset-0 text-center">
                  <p className="text-[0.8rem]">No content available yet</p>
                </div>
              </div>
              */}
              {/* -- When there are no images available yet load the collection profile picture in theese 2 image tags up here. --*/}
              {imageURL && imageURL.length > 0 ? (
                <img
                  src={imageURL}
                  alt={name}
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  className="rounded-t-md group-hover:scale-110 duration-300 ease-out object-cover w-full h-full"
                />
              ) : imageRaw && imageRaw.length > 0 ? (
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(imageRaw)}`}
                  alt={name}
                  className="rounded-t-md group-hover:scale-110 duration-300 ease-out object-cover w-full h-full"
                />
              ) : null}

              {(endTime && endTime > Date.now() / 1000) ?
                <div className="flex flex-row absolute right-3 bottom-[10px] pt-1 pl-2 pr-2 pb-1 bg-jacarta-600 dark:bg-opacity-70 bg-opacity-70 dark:bg-jacarta-600 rounded-xl">
                  <div className="mt-[4px] mr-2 w-1 h-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="6"
                      viewBox="0 0 16 16"
                      className="bi bi-circle-fill fill-green"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                  </div>
                  <div className="text-white flex justify-center flex-col text-xs font-semibold">
                    Ends in: <p className="text-accent font-display">{arbDateTimeDiff(endTime)}</p>
                  </div>
                </div> : (endTime && endTime <= Date.now() / 1000) ?
                  <div className="flex flex-row absolute right-3 bottom-[10px] pt-1 pl-2 pr-2 pb-1 bg-jacarta-600 dark:bg-opacity-70 bg-opacity-70 dark:bg-jacarta-600 rounded-xl">
                    <div className="mt-[4px] mr-2 w-1 h-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        viewBox="0 0 16 16"
                        className="bi bi-circle-fill fill-red"
                      >
                        <circle cx="8" cy="8" r="8" />
                      </svg>
                    </div>
                    <div className="text-white flex justify-center flex-col text-xs font-semibold">
                      Ended
                    </div>
                  </div> : <></>
              }
            </figure>
            <div className="px-3 ">
              <div className="flex flex-row justify-between">
                <div className="mt-3 flex items-center justify-between">
                  <Link href={{ pathname: `/item/${contractAddress}/${id}`, query: query }}>
                    <span className="font-display text-jacarta-700 text-base dark:text-white overflow-hidden line-clamp-1">
                      {name}
                    </span>
                  </Link>
                </div>
                {type == "ERC1155" && balance && (
                  <div className="flex justify-end text-accent font-bold ml-2 mt-[11px]">
                    {balance}x
                  </div>
                )}
              </div>
              <div className="mt-2 text-sm hoverhidden1">
                <span className="dark:text-jacarta-200 flex flex-row text-jacarta-700 mr-1">
                  <ArbitrumSVG />{" "}
                  <span className="my-auto font-medium text-accent ml-2">
                    {unitPrice ? unitPrice : "Not Listed"}
                  </span>
                </span>
              </div>
              <div className="absolute bottom-6 left-0 w-full bg-indigo-600 text-center max-h-60 hoverhidden1">
                {topOffer && (
                  <div className="flex bottom-0 flex-col">
                    <div className="text-jacarta-400 flex font-medium text-xs dark:text-jacatra-300 ml-4 mr-auto">
                      Top offer:{" "}
                      <p className="text-accent ml-1 mr-1">
                        {topOffer.currencyValue ? topOffer.currencyValue.displayValue : topOffer}
                      </p>{" "}
                      ARB
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="absolute bottom-0 left-0 w-full p-5 mt-1 bg-indigo-600 text-center max-h-60">
              {(sellButton || cancelButton || buyButton || makeOfferButton || bidButton) &&
                <div className="assetthumbbutton py-1 hidden rounded-md bg-accent-dark mt-8 flex items-center justify-between flex flex-col">
                  {sellButton && (
                    <button
                      className="w-full text-white text-sm font-semibold"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(
                          updateSelItem({
                            contractAddress: contractAddress,
                            contractName: contractName,
                            tokenId: id,
                            tokenTitle: name,
                            tokenTokenType: type,
                            tokenBalance: balance ? balance : 1,
                            tokenImage: imageURL,
                            creatorRoyalty: creatorRoyalty,
                            floorPrice: floorPrice
                          })
                        );
                        dispatch(selModalShow());
                      }}
                    >
                      Sell
                    </button>
                  )}
                  {cancelButton && (
                    <button
                      className="w-full text-white text-sm font-semibold"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(
                          updateCancelItem({
                            contractAddress: contractAddress,
                            tokenId: id,
                            tokenTitle: name,
                            tokenImage: imageURL,
                            listingId: listingId,
                            listingType: listingType,
                            tokenPrice: unitPrice
                          })
                        );
                        dispatch(cancelModalShow());
                      }}
                    >
                      Cancel {/* {listingType == "direct" ? "sale" : "auction"} */}
                    </button>
                  )}
                  {buyButton && (
                    <button
                      className="w-full text-white text-sm font-semibold"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(
                          updateBuyItem({
                            contractAddress: contractAddress,
                            tokenId: id,
                            balance: balance,
                            tokenTitle: name,
                            tokenImage: imageURL,
                            listingId: listingId,
                            listingType: listingType,
                            tokenPrice: unitPrice,
                            currencyContractAddress: currencyContractAddress,
                            currencySymbol: currencySymbol,
                            currencyDecimals: currencyDecimals
                          })
                        );
                        dispatch(buyModalShow());
                      }}
                    >
                      Buy
                    </button>
                  )}
                  {makeOfferButton && (
                    <button
                      className="w-full text-white text-sm font-semibold"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(
                          updateMakeOffer({
                            contractAddress: contractAddress,
                            tokenId: id,
                            tokenTitle: name,
                            tokenImage: imageURL,
                            listingId: listingId,
                            listingType: listingType,
                            tokenPrice: unitPrice
                          })
                        );
                        dispatch(makeOfferModalShow());
                      }}
                    >
                      Make Offer
                    </button>
                  )}
                  {bidButton && (
                    <button
                      className="w-full text-white text-sm font-semibold"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(
                          updateBidItem({
                            contractAddress: contractAddress,
                            tokenId: id,
                            tokenTitle: name,
                            tokenImage: imageURL,
                            auctionId: listingId,
                            listingType: listingType,
                            minBidPrice: minBidPrice
                          })
                        );
                        dispatch(bidModalShow());
                      }}
                    >
                      Place Bid
                    </button>
                  )}
                </div>
              }
            </div>
          </div>
        </article>
      </div>
    </Link>
  );
};

export default AssetThumb;
