import { logger } from "../arbLogger";

const formatTime = ( t: number ) => t < 10 ? '0' + t : t;

const arbDateTimeDiff = (timestamp: number, ago?: boolean) => {
    let remains = ago?Math.floor(Date.now()/1000)-timestamp:timestamp-Math.floor(Date.now()/1000)
    const seconds = remains % 60;
    remains = (remains -seconds) /60;
    const minutes = remains % 60;
    remains = (remains -minutes) / 60;
    const hours = remains % 24; 
    remains = (remains - hours) /24;
    const days = remains % 30;
    const years = Math.trunc(remains / 365);
    remains = (remains - days) / 30;
    logger.debug("arbDateTime", days, hours, minutes, seconds)
    if (years>0) return `${years} years +`
    else if (remains>0) return `${remains} months +`
    else if (days>0) return days+"d "+hours+ "h "+ formatTime(minutes) + "m"
    else if (hours>0) return hours+ "h "+ formatTime(minutes) + "m"
    return formatTime(minutes) + "m " + formatTime(seconds)+"s"
  };
  
  export default arbDateTimeDiff;