import { FC } from "react";
import { useContract, useEnglishAuctionWinningBid, useMinimumNextBid } from "@thirdweb-dev/react";
import { logger } from "../../../utils/arbLogger";
import AssetThumb from "./AssetThumb";

interface AssetThumbProps {
  id: string;
  imageURL: string;
  name: string;
  description: string;
  unitPrice?: string;
  minBidPrice?: string;
  type?: string;
  balance?: number;
  totalSupply?: number;
  listingId?: string;
  listingType?: string;
  contractAddress: string;
  contractName?: string;
  contractSymbol?: string;
  currencyContractAddress?: string;
  currencyName?: string;
  currencySymbol?: string;
  currencyDecimals?: number;
  endTime: number;
  sellButton?: boolean;
  buyButton?: boolean;
  cancelButton?: boolean;
  makeOfferButton?: boolean;
  bidButton?: boolean;
  isOwner?: boolean;
  topOffer?: any;
}



const AuctionAssetThumb: FC<AssetThumbProps> = ({
  id, contractAddress,
  imageURL, name,
  description, unitPrice, minBidPrice, balance, totalSupply, listingId, listingType,
  currencyContractAddress, contractName, contractSymbol,
  currencyName, currencySymbol,
  currencyDecimals, endTime, type,
  sellButton, buyButton,
  cancelButton, makeOfferButton, bidButton, isOwner, topOffer
}) => {
  const { contract: marketplaceContract } = useContract(
    process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS,
    "marketplace-v3",
  );
  const {
    data: winningBid,
  } =  useEnglishAuctionWinningBid(marketplaceContract, listingId);
  const { data: minimumNextBid, isLoading, error } = useMinimumNextBid(marketplaceContract, listingId);
    logger.debug("WINNING BID", winningBid, minimumNextBid)
  return (
    <AssetThumb id={id} contractAddress={contractAddress}
                imageURL={imageURL} name={name} balance={balance}
                description={description} unitPrice={winningBid?winningBid.bidAmountCurrencyValue.displayValue:unitPrice}
                minBidPrice={minimumNextBid?minimumNextBid.displayValue:minBidPrice}
                endTime={endTime}
                listingId={listingId} listingType={listingType}
                cancelButton={winningBid?false:cancelButton}
                bidButton={bidButton} isOwner={isOwner} topOffer={topOffer}
              />
  )
}

AuctionAssetThumb.defaultProps = {
  type: "ERC721",
  sellButton: false,
  buyButton: false,
  cancelButton: false,
  makeOfferButton: false,
  bidButton: false,
  listingType: "auction",
  totalSupply: 1,
  isOwner: false,
}

export default AuctionAssetThumb;